/* ItemPage
 */

import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Breakpoints } from "../util/breakpoints"
import Header from "../components/header"
import Title from "../components/title"
import Carousel from "../components/carousel"
import PurchaseActions from "../components/purchase-actions"
import useSiteMetadata from "../hooks/use-site-metadata"
import useUserPreferences from "../hooks/use-user-preferences"
import { formatCurrencyString } from "../util/currency"

const MainGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.has.image ? "auto auto" : "auto"};
  grid-template-areas: ${props => props.has.image ?
    '"image" "info"' :
    '"info"'
  };
  grid-column-gap: 15px;
  align-items: start;
  margin-top: 15px;

  @media ${Breakpoints.smallOrLarger} {
    grid-template-columns: auto auto;
    grid-template-areas:
      ${props => props.has.image ? '"image info"' : '"info"'};
  }
`

const InfoGrid = styled.div`
  grid-area: info;
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-areas:
    ${props => props.has.actions ? '"actions"' : ''}
    ${props => props.has.price ? '"price"' : ''}
    ${props => props.has.description ? '"description"' : ''}
    ${props => props.has.keywords ? '"keywords"' : ''};
  align-items: start;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const ImageContainer = styled.div`
  grid-area: image;
  max-width: 92vw;
  @media ${Breakpoints.smallOrLarger} {
    max-width: 50vw;
  }
`

const Price = styled.p`
  grid-area: price;
`

const Actions = styled.div`
  grid-area: actions;
  margin-top: 15px;
  margin-bottom: 10px;
`

const Description = styled.p`
  grid-area: description;
  font-style: italic;
`

const Keywords = styled.p`
  grid-area: keywords;
`

export default function ItemPage(props) {
  const breakpoints = useBreakpoint()
  const siteMetadata = useSiteMetadata()
  const userPreferences = useUserPreferences()
  // item set in page context by gatsby-node.js
  const item = props.pageContext.item
  const price = item.price > 0 ?
    formatCurrencyString(item.price, item.currency) :
    null
  const has = {
    image: item.images.length > 0,
    actions: userPreferences.purchase,
    price: !!price,
    description: !!item.description,
    keywords: !!item.keywords,
  }

  return <>
    <Header
      title={item.name}
      url={`${siteMetadata.siteUrl}${item.path}`}
    />
    <Title text={item.name} />
    <MainGrid has={has}>
      {has.image &&
        <ImageContainer>
          <Carousel
            images={item.images}
            imageContainerStyle={{maxHeight: breakpoints.smallOrLarger ? "70vh" : "40vh"}}
          />
        </ImageContainer>
      }
      <InfoGrid has={has}>
        {has.actions &&
          <Actions>
            <PurchaseActions name={item.name} product={item} />
          </Actions>
        }
        {has.price &&
          <Price data-qa={"ItemPrice"}>
            {price}
          </Price>
        }
        {has.description &&
          <Description data-qa={"ItemDescription"}>
            {item.description}
          </Description>
        }
        {has.keywords && 
          <Keywords data-qa={"ItemKeywords"}>
            <b>Keywords</b>: {item.keywords.join(", ")}
          </Keywords>
        }
      </InfoGrid>
    </MainGrid>
  </>
}
